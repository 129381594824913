import { IconButtonAnchor } from 'src/components/ui-components/IconButton';
import { Stack } from 'src/components/ui-components/Stack';
import FeatureControl from 'src/components/utils/FeatureControl';
import { useIsPsaProduct } from 'src/utils/siteInfo';
import { useIsValidIndexControlName } from 'src/apis/indexAPI';
import { useGetNotificationCenter, useGetIsApprovalEnabled } from 'src/apis/notificationCenterAPI';
import { useGetReportWidgetAll } from 'src/apis/reportingWidgetsAPI';
import { MilestoneFeed } from './components/MilestoneFeed';
import ReportingWidgetContainer from './components/ReportingWidgetContainer';
import { WidgetFeedback } from './components/WidgetFeedback';
import NameArea from './components/NameArea';
import TimeAccuracyWidget from './components/TimeAccuracyWidget';
import BillablePercentageWidget from './components/BillablePercentageWidget';
import ExternalPercentageWidget from './components/ExternalPercentageWidget';
import { NotificationCenter } from './components/NotificationCenter';

import styles from './Home.module.scss';
import { InternalMessageWidget } from './components/InternalMessageWidget';
import { WidgetGrid } from './components/WidgetGrid';

export const Home = () => {
  const { isValidIndexControlName: hasMilestonesFeatureAccess } =
    useIsValidIndexControlName('Milestones');
  const { isValidIndexControlName: hasNotificationCenterFeatureAccess } =
    useIsValidIndexControlName('NotificationCenter');
  const { isApprovalEnabled } = useGetIsApprovalEnabled();
  const { itemsToTakeActionOn } = useGetNotificationCenter();
  const hasSidebar =
    hasMilestonesFeatureAccess || (hasNotificationCenterFeatureAccess && isApprovalEnabled);
  const isPsaProduct = useIsPsaProduct();
  const { isReportingWidgetEnabled: hasReportingWidgetAccess } = useGetReportWidgetAll();
  // TODO: to replace line 30 with line below once the feature attribute is fixed.
  // const { isValidIndexControlName: hasReportingWidgetAccess } =
  //   useIsValidIndexControlName('Widget');
  return (
    <div className={styles.dashboard}>
      <div className={styles.name}>
        <NameArea />
      </div>
      {hasSidebar && (
        <div className={styles.mobileSidebar}>
          {isApprovalEnabled && hasNotificationCenterFeatureAccess ? (
            <IconButtonAnchor
              iconName="gppMaybe"
              iconSize="large"
              tooltipText="Feeds"
              href="#sidebar"
              data-automation-id="DashboardAnchorToSidebarGppMaybe"
              count={itemsToTakeActionOn}
              countTone={itemsToTakeActionOn > 0 ? 'warning' : 'success'}
            />
          ) : (
            <IconButtonAnchor
              iconName="flag"
              iconSize="large"
              tooltipText="Feeds"
              href="#sidebar"
              data-automation-id="DashboardAnchorToSidebarFlag"
            />
          )}
        </div>
      )}
      <div className={styles.main}>
        <WidgetGrid>
          {/* TODO: This should be handled by feature flag from api */}
          {isPsaProduct && (
            <FeatureControl indexControlName="Settings" actionName="GetFrontPageInternalMessage">
              <WidgetGrid.Item columns="4" data-automation-id="WidgetInternalMessageWidget">
                <InternalMessageWidget />
              </WidgetGrid.Item>
            </FeatureControl>
          )}
          {/* To hide all existing widgets when reporting widgets are available. Should use feature control */}
          {!hasReportingWidgetAccess && (
            <>
              <FeatureControl
                indexControlName="WidgetData"
                actionName="GetTimeRegistrationAccuracyInPeriodV2"
              >
                <WidgetGrid.Item columns="1" data-automation-id="WidgetRegistrationAccuracy">
                  <TimeAccuracyWidget />
                </WidgetGrid.Item>
              </FeatureControl>

              <FeatureControl
                indexControlName="WidgetData"
                actionName="GetWidgetDataExternalPercentageV2"
              >
                <WidgetGrid.Item columns="1" data-automation-id="WidgetExternalPercentage">
                  <ExternalPercentageWidget />
                </WidgetGrid.Item>
              </FeatureControl>

              <FeatureControl
                indexControlName="WidgetData"
                actionName="GetWidgetDataBillablePercentageV2"
              >
                <WidgetGrid.Item columns="1" data-automation-id="WidgetBillablePercentage">
                  <BillablePercentageWidget />
                </WidgetGrid.Item>
              </FeatureControl>
              <WidgetGrid.Item columns="1" data-automation-id="Feedback">
                <WidgetFeedback />
              </WidgetGrid.Item>
            </>
          )}
        </WidgetGrid>
        <br />
        <FeatureControl indexControlName="Widget">
          <ReportingWidgetContainer />
        </FeatureControl>
      </div>
      <div id="sidebar" className={styles.sidebar} data-automation-id="DashboardSidebar">
        <Stack verticalMargin="xxLarge">
          {isPsaProduct && (
            <FeatureControl indexControlName="NotificationCenter">
              <NotificationCenter />
            </FeatureControl>
          )}
          <FeatureControl indexControlName="Milestones">
            <MilestoneFeed />
          </FeatureControl>
        </Stack>
      </div>
    </div>
  );
};

export default Home;
